import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'

//用车订单列表
import consumer_car_order_carOrderListForOrderCenter from '@/lib/data-service/haolv-default/consumer_car_order_carOrderListForOrderCenter'
//用车订单列表状态统计
import consumer_car_order_carOrderStatusCountForOrderCenter from '@/lib/data-service/haolv-default/consumer_car_order_carOrderStatusCountForOrderCenter'
//用车原订单下载
import consumer_car_order_downloadCarOrderListDetails from '@/lib/data-service/haolv-default/consumer_car_order_downloadCarOrderListDetails'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'


export default {
    data() {
        return {
            loading: false,
            orderStatusData: 0,
            searchForm: {
                deptIdList: [],
                currentPage: 1,   //查询页码
                deptIds: [],     //部门-查询
                startDate: '',    //开始日期-查询
                endDate: '',      //结束日期-查询
                orderNo: '',      // 订单号
                orderStatus: 0,   //订单状态-查询
                pageSize: 10,     //每页记录条数
            },
            applyTime: [],
            totalCount: 0,   //总条数
            deptList: [],
            activeName: '0',
            tabList: [
                // // 订单状态  0=全部 10=超标审批中 2=待支付 3=待出票 4=已出票 5=出票失败 6=已取消
                {
                    name: "0",
                    label: "全部",
                    value: 0
                },
                {
                    name: "1",
                    label: "已完成",
                    value: 0
                },
                {
                    name: "2",
                    label: "待支付",
                    value: 0
                },
                {
                    name: "3",
                    label: "未完成",
                    value: 0
                },
            ],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.getDeptList();
        this.getOrderList();
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.startDate = moment(val[0]).format('YYYY-MM-DD 00:00:00');
                    this.searchForm.endDate = moment(val[1]).format('YYYY-MM-DD 23:59:59');
                } else {
                    this.searchForm.endDate = '';
                    this.searchForm.startDate = '';
                }
            }
        },
    },
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        // 获取订单列表
        getOrderList() {
            this.loading = true;
            this.searchForm.orderStatus = parseInt(this.activeName);

            consumer_car_order_carOrderListForOrderCenter(this.searchForm).then(res => {
                let orderList = res.datas.carOrderList.list;
                //出行人预处理
                orderList.forEach(value => {
                    let psgNameList = [];
                    if (value.psgName) {
                        psgNameList = value.psgName.split(',')
                    }
                    value.psgNameList = psgNameList
                });
                this.orderList = orderList;
                this.totalCount = res.datas.carOrderList.totalCount
            }).catch(err => {
                this.orderList = [];
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            });

            this.postTrainOrder();
        },
        //获取订单各状态数量
        postTrainOrder(){
            //预处理订单个状态数量
            let dataForm = this.searchForm;
            dataForm.orderStatus = 0;

            consumer_car_order_carOrderStatusCountForOrderCenter(dataForm).then(res => {
                res.datas.results.forEach((item,index) => {
                    this.tabList.forEach((tabItem,tabIndex) => {
                        if (item.status == tabItem.name) {
                            tabItem.value = item.orderNumber;
                        }
                    })
                });
            })
        },
        // 搜索按钮
        onSearch() {
            this.searchForm.currentPage = 1;
            this.getOrderList();
        },
        // 重置按钮--TRAIN
        onReset() {
            this.searchForm = {
                gmtEnd: '',
                gmtStart: '',
                deptIdList: [],
                deptIds: [],     //部门-查询
                passengerName: '', // 出行人姓名
                orderNo: '',   // 订单号
                orderStatus: 0,
                orderType: 3,
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            //重置日期选择器
            this.applyTime = [];
            this.onSearch()
        },
        // 订单状态--TRAIN
        getStatusToClass(val) {
            //订单状态 0：派单中 1：完成待支付 2：取消待支付 3：关闭 4：订单完成 5：待出发 6：待上车 7：行程中
            if (val === 4) {
                return 'green-text'
            } else if (val === 3) {
                return 'red-text'
            } else {
                return 'yellow-text'
            }
        },
        // 查看详情--TRAIN
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-car-order-details',
                query: {
                    orderNo: val.orderNo,
                    type: 'orderCenter'
                }
            })
        },
        //切换
        tabClick(data){
            this.orderStatusData = Number(data.name);
        },
        //下载
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false;
            this.searchForm.orderStatus = this.orderStatusData;
            consumer_car_order_downloadCarOrderListDetails(this.searchForm).then(res => {
                let url = res.datas.orderDetailsUrl;
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    });
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
    }
}
