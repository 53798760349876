//consumer_car_order_carOrderStatusCountForOrderCenter 订单中心-用车订单列表状态统计
//http://yapi.yinzhitravel.com/project/52/interface/api/15324
const __request = require(`./__request/__request_contentType_json`);
const consumer_car_order_carOrderStatusCountForOrderCenter = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/car/order/carOrderStatusCountForOrderCenter',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_car_order_carOrderStatusCountForOrderCenter;