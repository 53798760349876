//consumer_car_order_carOrderListForOrderCenter  订单中心-用车订单列表
//http://yapi.yinzhitravel.com/project/52/interface/api/15332
const __request = require(`./__request/__request_contentType_json`);
const consumer_car_order_carOrderListForOrderCenter = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/car/order/carOrderListForOrderCenter',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_car_order_carOrderListForOrderCenter;

