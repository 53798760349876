
//用车原订单下载
const __request = require(`./__request/__request_contentType_json`);
const consumer_car_order_downloadCarOrderListDetails = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/car/order/downloadCarOrderListDetails',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_car_order_downloadCarOrderListDetails;